import { Match, Switch, createEffect, createRenderEffect, splitProps, untrack } from "solid-js";
import { ASubmoduleMaterial, ShowSubModuleViewProps } from "../../models";
import { Ctrl, helper, log, state } from ":mods";
import { getMaterialDetails, getSubModuleDetails } from "../../apis";
import { getMaterialType, getModuleRoute, getStageRoute } from "../../methods";
import { ShowMaterial } from "../show-material";
import { MaterialFilter } from "./material-filter";
import { unwrap } from "solid-js/store";

export function ShowSubmodule(props: ShowSubModuleViewProps) {
  const [local, others] = splitProps(props, ["$", "submoduleID", "materialsFilter"]);
  const params = local.$.hooks.useParams();
  const submoduleID = params.id ?? local.submoduleID;
  const $details = state.createAsync([getSubModuleDetails, submoduleID]);
  const $materials = state.create(undefined as ASubmoduleMaterial[]);
  const Layer = local.$.actions.createLayer({
    backMsg: "Back To Modules",
    // allowLoopItemsOnNextAndPrevious: true,
    events: {
      backTo: () => {
        if ($details.state.success) {
          const is_student = local.$.actions.getTokenType() === local.$.CONST.ACCOUNT.TOKEN_TYPE.student;
          const data = $details.unwrap.value.data;
          return is_student ? getModuleRoute(data.module_details.id) : getStageRoute(data.module_details.stage_id);
        }
        return undefined;
      },
      onNextNotFound: () => {
        local.$.actions.pushToast({
          type: "info",
          message: "This is the last item in this submodule!",
        });
      },
    },
  });

  local.$.actions.clearLayout();
  return (
    <Switch fallback={<div>unknown resource state</div>}>
      <Match when={$details.state.loading}>loading...</Match>
      <Match when={$details.state.failed}>an error occured while fetching {$details.error}</Match>
      <Match when={$details.state.success}>
        <Layer
          nonAnchorItems={[
            {
              element: () => <span class="font-bold capitalize my-1.5">{$details.value.data.module_details.name}</span>,
            },
            {
              element: () => <span class="capitalize mb-1.5">{$details.value.data.name}</span>,
            },
            {
              element: () => (
                <MaterialFilter
                  data={$details.value.data.materials}
                  onFiltered={({ arr, filter }) => {
                    $materials.set(arr);
                  }}
                />
              ),
            },
          ]}
          items={$materials.value?.map((_material) => {
            const material = unwrap(_material);
            const type = getMaterialType(material);
            let name = material.name;
            const is_tp22 = material?.design_name?.toLowerCase().startsWith("tp22");
            if (type === "lesson") {
              // const lowercase = name.trim().toLowerCase();
              // if (lowercase.startsWith("tp")) {
              //   const n = lowercase.length - lowercase.replace(/^[^\s]*\s*/, "").length;
              //   name = material.name.substring(n);
              // }
            } else if (is_tp22) {
              // if (!helper.env.isDev) {
              // }
              name = name.split("|")[0];
              // const split = material?.design_name?.split("_")
              // if (split[1].startsWith("task")) {
              // }
              // if (!tp22_has_been_dsiplayed) {
              //   name = name.split("|")[0];
              //   tp22_has_been_dsiplayed = true;
              // } else {
              //   return undefined;
              // }
            }
            let idle_icon = "";
            let active_icon = "";
            if (type === "lesson") {
              idle_icon = "icon-local-submodule-material:lesson-idle";
              active_icon = "icon-local-submodule-material:lesson-active";
            } else if (type === "question") {
              idle_icon = "icon-local-submodule-material:question-idle";
              active_icon = "icon-local-submodule-material:question-active";
            } else if (type === "task") {
              idle_icon = "icon-local-submodule-material:task-idle";
              active_icon = "icon-local-submodule-material:task-active";
            }
            return {
              title: {
                title: `${name}${material.complated ? " - COMPLETED" : ""}`,
                subtitle: material.short_description,
              },
              element: () => <ShowMaterial $={local.$} material={material} />,
              icon: material.complated
                ? "icon-local-submodule-material:done-white"
                : {
                    idle: idle_icon,
                    active: active_icon,
                  },
              titleClass: "!text-0.8rem",
              subtitleClass: "!text-0.8rem",
              class: " flex flex-row",
            };
          })}
        ></Layer>
      </Match>
    </Switch>
  );
}
